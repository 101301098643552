import { Fragment, useEffect } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  AcademicCapIcon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  BookOpenIcon,
  ChartBarIcon,
  ChartBarSquareIcon,
  CreditCardIcon,
  FireIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import mizarIcon from 'assets/img/logo/mizar_icon.svg';
import { getAssetsUrl } from 'utils/urlUtils';
import { formatWithoutDecimals } from 'utils/FormatNumber';
import { FormatTextDelta } from 'utils/FormatText';
import { Link, NavLink, useHistory } from 'react-router-dom';
import config from 'config';
import classNames from 'classnames';
import { cexMenu, cexNavigation, dexMenu, dexNavigation } from './config';
import ExpandableItem from './components/ExpandableItem';
import Tag from 'modules/shared-components/data-display/tag';
import { Button } from 'modules/shared-components/button/button';
import CreditsOverview from './components/CreditsOverview';
import { StarIcon } from 'modules/shared-components/asset/StarIcon';
import { useUserState } from 'modules/user/UserContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import UserService from 'api/services/UserService';
import { PriceService, priceServiceKeys } from 'api/services/PriceService';
import { duration } from 'moment';
import { useStarLevel } from 'api/services/StarLevelService';
import { ToggleButton } from 'modules/shared-components/ToggleButton';
import {
  DexSwitchMode,
  useExchangeMode,
} from 'modules/DexSwitch/DexSwitchContext';
import { twMerge } from 'tailwind-merge';
import { GasInfo } from './components/GasInfo';
import { UserV1 } from 'api/user';
import { LinkAccountButton } from './components/LinkAccountButton';
import Div100vh from 'react-div-100vh';
import { PLANS } from '../../../app/dashboard/fee-manager-new/components/plansComponents/PLANS';
import { FeeService, feesInfoKeys } from 'api/services/FeeServiceNew';
import { StarLevelResponse } from 'api/star-level';
import Tooltip from 'modules/shared-components/tooltip';
import {
  marketplaceKeys,
  MarketplaceService,
} from 'api/services/MarketplaceService';

//remove 25th of October
function useUpdateUser() {
  const { user, updateUser } = useUserState();
  useEffect(() => {
    async function updateUserName() {
      try {
        const fetchedUser = await UserService.getCurrent();
        updateUser({ ...(user as UserV1), username: fetchedUser.username });
      } catch {
        console.error('Error fetching user');
      }
    }

    if (user && 'id' in user && !user.username) {
      void updateUserName();
    }
  }, [
    user && 'id' in user ? user?.username : '',
    user && 'id' in user ? user?.id : undefined,
  ]);
}

const AvatarImg = ({
  user,
  starLevel,
}: {
  user: UserV1 | undefined;
  starLevel: StarLevelResponse | undefined;
}) => {
  if (user && user.avatar_url) {
    return (
      <img
        className="rounded-circle h-10 w-10"
        src={user.avatar_url}
        alt="Mizar avatar"
      />
    );
  } else if (starLevel) {
    return (
      <StarIcon
        img={starLevel.img}
        className="rounded-circle h-10 w-10"
        alt="User Star Level"
      />
    );
  } else {
    return (
      <img
        className="rounded-circle h-10 w-10"
        src={mizarIcon}
        alt="Mizar Icon"
      />
    );
  }
};
export default function TopNavbar() {
  const history = useHistory();
  const { user, logoutUser } = useUserState();
  const { data: starLevel } = useStarLevel();

  const { mode, setMode } = useExchangeMode();

  const { data: plan } = useQuery({
    queryKey: feesInfoKeys.getPlanInfo(),
    queryFn: () => FeeService.getPlanInfo(),
    staleTime: 10 * 60 * 1000,
    enabled: !!user,
  });

  const { data: traderLevel } = useQuery({
    queryKey: marketplaceKeys.getUserTraderLevel(),
    queryFn: () => MarketplaceService.getUserTraderLevel(),
    staleTime: duration('2', 'hour').asMilliseconds(),
    cacheTime: duration('2', 'hour').asMilliseconds(),
    enabled: !!user,
  });

  const activePlanId = plan ? plan.activePlanId : null;
  const activePlanDetail = PLANS.find((p) => p.id === activePlanId) ?? null;

  const { mutate: logout } = useMutation({
    mutationFn: UserService.logout,
    onSuccess: logoutUser,
  });
  useUpdateUser();

  const navItems = mode === DexSwitchMode.Cex ? cexNavigation : dexNavigation;
  const menu = mode === DexSwitchMode.Cex ? cexMenu : dexMenu;
  const classNavLink =
    'xxs:p-4 flex flex-col items-center justify-center dark:text-black-50 xxs:text-2xl xxs:font-semibold xxs:border-black-700 xxs:border-solid xxs:border';
  return (
    <Disclosure
      as="nav"
      className="bg-white-50 xxs:shadow-sm dark:shadow-none dark:bg-black-800 xxs:fixed xxs:top-0 xxs:inset-x-0 z-30"
    >
      {({ open, close }) => (
        <>
          <div className="lg:mx-8">
            <div className="relative flex items-center justify-between h-16 max-w-screen-2xl xxs:w-full mx-auto">
              <div className="inset-y-0 flex items-center sm:hidden">
                <Disclosure.Button
                  data-testid="mobile-nav-toggle"
                  className="inline-flex lg:hidden items-center justify-center xxs:p-2 rounded-md text-gray-400 hover:text-white hover:bg-black-700 focus:outline-none border-none"
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <a
                className="xxs:ml-2 hidden lg:block"
                href={'https://' + config.APPLICATION_DOMAIN}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="h-8 w-auto"
                    src={mizarIcon}
                    alt="mizar_icon"
                  />
                </div>
              </a>
              {user && !config.isTelegramBrowser && (
                <ToggleButton
                  className="xxs:ml-3"
                  id="dex_switch"
                  options={[
                    {
                      name: 'CEX',
                      value: DexSwitchMode.Cex,
                    },
                    {
                      name: 'DEX',
                      value: DexSwitchMode.Dex,
                    },
                  ]}
                  onChange={(m: DexSwitchMode) => {
                    setMode(m);
                    if (m === DexSwitchMode.Dex) {
                      history.push('/dex');
                    }
                    if (m === DexSwitchMode.Cex) {
                      history.push('/');
                    }
                  }}
                  value={mode}
                />
              )}
              <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
                {(user || mode === DexSwitchMode.Dex) && (
                  <div className="hidden lg:block sm:ml-6">
                    <div className="flex space-x-2">
                      {navItems.map((item) => {
                        return (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            id={item.id}
                            exact={false}
                            strict={false}
                            activeClassName="bg-white-300 dark:bg-black-900 text-white-900 dark:text-white"
                            className={classNames(
                              'text-white-900 dark:text-gray-300 hover:bg-white-200 dark:hover:bg-black-700 dark:hover:text-white',
                              'xxs:px-3 xxs:py-2 rounded-md text-xs font-medium xxs:flex xxs:flex-nowrap items-center'
                            )}
                          >
                            {item.name}
                            {item.tag && (
                              <div className="xxs:ml-2">
                                <Tag color="green" condensed>
                                  {item.tag}
                                </Tag>
                              </div>
                            )}
                          </NavLink>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 space-x-2">
                {mode === DexSwitchMode.Cex && <PriceInfo mode={mode!} />}
                {mode === DexSwitchMode.Dex && !!user && <LinkAccountButton />}
                {mode === DexSwitchMode.Dex && <GasInfo />}
                {mode === DexSwitchMode.Cex && <CreditsOverview />}

                {/* Profile dropdown */}
                {!user && (
                  <Button
                    className="xxs:ml-2"
                    variant="primary"
                    as={Link}
                    to="/login"
                  >
                    Log In
                  </Button>
                )}
                {!!user && (
                  <Menu
                    as="div"
                    className="xxs:ml-3 relative z-1020"
                    id="account_star"
                  >
                    <div>
                      <Menu.Button className="border-none">
                        <span className="sr-only">Open user menu</span>
                        <span className="account-user-avatar">
                          {starLevel ? (
                            <div className="flex space-x-1 items-center">
                              <AvatarImg
                                user={user as UserV1}
                                starLevel={starLevel}
                              />
                              {mode === DexSwitchMode.Cex &&
                              activePlanDetail ? (
                                <div className="hidden xl:block text-left space-y-0">
                                  <div className="text-xs">
                                    {activePlanDetail?.label ?? '--'}
                                  </div>

                                  {activePlanId !== 'pro' &&
                                  activePlanId !== 'institutional' ? (
                                    <Link
                                      to="/dashboard/fee-manager?activeTab=Plan"
                                      className="text-blue-500 hover:text-blue-600 text-[10px]"
                                    >
                                      Upgrade
                                    </Link>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          ) : (
                            <div className="h-10 w-10" />
                          )}
                        </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className=" dark:bg-black-700 dark:text-white-50 fixed lg:absolute top-16 lg:top-12 lg:mt-1 left-0 lg:left-auto right-0 lg:w-64 h-screen lg:h-auto overflow-y-auto lg:overflow-y-auto lg:rounded-md shadow-lg pt-1 xxs:bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <div className="xxs:px-2 pt-1 flex justify-center">
                            {(user as UserV1) &&
                            'username' in user &&
                            user.username ? (
                              <div className="flex space-x-1 items-center">
                                {activePlanDetail &&
                                mode === DexSwitchMode.Cex ? (
                                  <div className="flex space-x-1 items-center text-xs">
                                    <Link
                                      to="/dashboard/fee-manager?activeTab=Plan"
                                      className="text-white"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <Tooltip text="Your Mizar plan, used to get access to more advanced features. Click to learn more.">
                                        <div>
                                          {activePlanDetail?.label ?? '--'}
                                        </div>
                                      </Tooltip>
                                    </Link>
                                    <div>|</div>
                                    {starLevel ? (
                                      <Link
                                        to="/dashboard/staking"
                                        className="text-white"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Tooltip text="Your Star level, used to reduce fees. Click to learn more.">
                                          <div className="flex space-x-1 items-center">
                                            <StarIcon
                                              img={starLevel.img}
                                              className="rounded-circle h-4 w-4"
                                              alt="User Star Level"
                                            />
                                            <div>{starLevel.level}</div>
                                          </div>
                                        </Tooltip>
                                      </Link>
                                    ) : (
                                      '--'
                                    )}
                                    <div>|</div>
                                    {traderLevel ? (
                                      <Link
                                        to="/dashboard/rewards-dashboard?activeTab=Performance"
                                        className="text-white"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <Tooltip text="Your trader level, used to get access to more advanced features and rewards when you're copy-traded. Click to learn more.">
                                          <div className="flex space-x-1 items-center">
                                            <img
                                              alt={`trader-level-${traderLevel?.traderLevel.name.toLowerCase()}`}
                                              className="h-4 w-4"
                                              src={require(`assets/img/trader-levels/${traderLevel?.traderLevel.name.toLowerCase()}.png`)}
                                            />
                                            <div className="capitalize">
                                              {traderLevel?.traderLevel.name.toLowerCase()}
                                            </div>
                                          </div>
                                        </Tooltip>
                                      </Link>
                                    ) : (
                                      '--'
                                    )}
                                  </div>
                                ) : (
                                  <div>{user?.username}</div>
                                )}
                              </div>
                            ) : (
                              '--'
                            )}
                          </div>
                        </Menu.Item>

                        <div className="h-0.5 xxs:mt-2 bg-white-400 dark:bg-black-500" />
                        {menu.map((item) => {
                          return (
                            <Menu.Item key={item.name}>
                              <NavLink
                                to={item.href}
                                activeClassName="bg-black-100 dark:bg-black-800"
                                className={classNames(
                                  'block xxs:px-8 lg:px-4 xxs:py-5 lg:py-2 text-xs text-gray-700  dark:text-white-500 hover:dark:text-50 dark:hover:bg-black-800 hover:bg-black-100'
                                )}
                              >
                                {item.icon}
                                <span className="ml-2">{item.name}</span>
                              </NavLink>
                            </Menu.Item>
                          );
                        })}
                        <Menu.Item>
                          <button
                            onClick={() => logout()}
                            className=" w-100 xxs:px-8 lg:px-4 xxs:py-5 lg:py-2 text-xs text-gray-700 dark:hover:bg-black-800   dark:text-white-500 hover:dark:text-50 border-none hover:bg-black-100 text-left"
                          >
                            <ArrowLeftOnRectangleIcon className="h-5 xxs:mr-2" />{' '}
                            LOGOUT
                          </button>
                        </Menu.Item>

                        <div className="h-0.5 xxs:mt-2 bg-white-400 dark:bg-black-500" />

                        <Menu.Item>
                          {mode === DexSwitchMode.Dex ? (
                            <a
                              href="https://docs.mizar.com/sniper-bot-manual"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="hidden lg:block xxs:px-4 xxs:py-3 text-sm bg-blue-500  text-white border-none hover:bg-blue-600 text-left"
                            >
                              <BookOpenIcon className="h-5 w-5 xxs:mr-2" />{' '}
                              <span className="self-center">SNIPER MANUAL</span>
                            </a>
                          ) : (
                            <a
                              href="https://docs.mizar.com/c-mizar-manual/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="hidden lg:block xxs:px-4 xxs:py-3 text-sm bg-blue-500  text-white border-none hover:bg-blue-600 text-left"
                            >
                              <BookOpenIcon className="h-5 w-5 xxs:mr-2" />{' '}
                              <span className="self-center">DOCS</span>
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
              </div>
            </div>
          </div>

          {mode === DexSwitchMode.Cex ? (
            <Disclosure.Panel className="lg:hidden fixed top-16 w-full bg-white-200 dark:bg-black-900 z-1020 h-screen overflow-y-scroll">
              <div
                data-testid="mobile-sidebar"
                className="relative xxs:p-6 flex flex-column justify-between overflow-y-scroll"
                style={{ height: 'calc(100vh - 4rem)' }}
              >
                <div className="pb-[64px] space-y-4">
                  {navItems.map((item) => {
                    const isActive =
                      window.location.pathname.indexOf(item.href) >= 0;
                    if (item.subMenus)
                      return (
                        <ExpandableItem
                          key={item.id}
                          icon={item.icon}
                          name={item.name}
                          isActive={isActive}
                          closeNav={close}
                          subMenus={item.subMenus}
                          tag={item.tag}
                        />
                      );
                    return (
                      <Disclosure.Button
                        key={item.name}
                        as={NavLink}
                        to={item.href}
                        className={classNames(
                          'hover:bg-blue-100 dark:hover:bg-blue-900 text-gray-500 dark:text-gray-300',
                          'block xxs:px-3 xxs:py-6 rounded-md text-base font-medium dark:hover:text-white hover:text-black-800 dark:text-white xxs:inline-flex xxs:items-center xxs:flex-nowrap xxs:w-full'
                        )}
                        activeClassName="bg-blue-200 dark:bg-blue-800 xxs:text-black-900 xxs:dark:text-white"
                        aria-current={isActive ? 'page' : undefined}
                      >
                        <span className="text-blue-600 mr-2">{item.icon}</span>{' '}
                        {item.name}
                        {item.tag && (
                          <div className="xxs:ml-2 xxs:flex items-center">
                            <Tag color="green" condensed>
                              {(item as { tag?: string }).tag}
                            </Tag>
                          </div>
                        )}
                      </Disclosure.Button>
                    );
                  })}
                </div>
                <nav className="flex justify-center space-x-4 py-8 bottom-0 left-0 fixed xxs:bg-white w-full dark:bg-black-800">
                  <a
                    href="https://docs.mizar.com/c-mizar-manual/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-medium text-white-900 hover:text-white-700 dark:text-white-600 dark:hover:text-white-700"
                  >
                    DOCS
                  </a>
                  <span
                    className="inline-block border-l border-tailwind border-gray-300"
                    aria-hidden="true"
                  />
                  <a
                    href="https://discord.gg/gM4mAYMeWG"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-medium text-white-900 hover:text-white-700 dark:text-white-600 dark:hover:text-white-700"
                  >
                    LIVE SUPPORT
                  </a>
                  <span
                    className="inline-block border-tailwind border-l border-gray-300"
                    aria-hidden="true"
                  />
                  <a
                    href="https://twitter.com/mizar_com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-medium text-white-900 hover:text-white-700 dark:text-white-600 dark:hover:text-white-700"
                  >
                    TWITTER
                  </a>
                </nav>
              </div>
            </Disclosure.Panel>
          ) : (
            <Disclosure.Panel className="lg:hidden flex items-center justify-center w-full bg-white-200 dark:bg-black-900 z-1020">
              <Div100vh className="flex justify-center items-center w-full">
                <div className="grid my-auto w-full grid-cols-2 outline-1 outline outline-offset-[-1px] outline-black-900 relative">
                  <Disclosure.Button
                    as={NavLink}
                    className={classNavLink}
                    to={'/dex/search'}
                  >
                    <div className="space-y-1 text-center">
                      <ChartBarSquareIcon className="h-10 w-10" />
                      <div className="text-sm">SNIPE</div>
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Button
                    as={NavLink}
                    className={classNavLink}
                    to={'/dex/wallets'}
                  >
                    <div className="space-y-1 text-center">
                      <CreditCardIcon className="h-10 w-10" />
                      <div className="text-sm">WALLET</div>
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Button
                    as={NavLink}
                    className={classNavLink}
                    to={'/dex/trade-monitoring'}
                  >
                    <div className="space-y-1 text-center">
                      <FireIcon className="h-10 w-10" />
                      <div className="text-sm">SNIPES</div>
                    </div>
                  </Disclosure.Button>

                  <Disclosure.Button
                    as={NavLink}
                    className={classNavLink}
                    to={'/dex/trade-monitoring?activeTab=history'}
                  >
                    <div className="space-y-1 text-center">
                      <ChartBarIcon className="h-10 w-10" />
                      <div className="text-sm">HISTORY</div>
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Button
                    as={NavLink}
                    className={classNavLink}
                    to={'/dex/research'}
                  >
                    <div className="space-y-1 text-center">
                      <AcademicCapIcon className="h-10 w-10" />
                      <div className="text-sm">RESEARCH</div>
                    </div>
                  </Disclosure.Button>
                  <Disclosure.Button
                    as={NavLink}
                    className={classNavLink}
                    to={'/dex/bots?activeTab=volatility-bot'}
                  >
                    <div className="space-y-1 text-center">
                      <i className="mdi mdi-robot text-4xl" />
                      <div className="text-sm">BOTS</div>
                    </div>
                  </Disclosure.Button>
                </div>
              </Div100vh>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  );
}

function PriceInfo({ mode }: { mode: DexSwitchMode }) {
  const { data: prices } = useQuery({
    queryKey: priceServiceKeys.getCryptoPrice(),
    queryFn: PriceService.getCryptoPrice,
    refetchInterval: duration('1', 'minute').asMilliseconds(),
  });

  return (
    <>
      {mode === DexSwitchMode.Cex && (
        <div className="font-sm py-1 mb-0 align-items-center xxs:mx-2 hidden xl:block">
          <img
            src={getAssetsUrl() + '/static/img/cryptocurrencies/icons/btc.png'}
            className="xxs:mr-2 align-center h-5"
            alt=""
          />
          ${prices?.btc_price ? formatWithoutDecimals(prices.btc_price) : '--'}{' '}
          <span className="  xxs:ml-1   ">
            {prices?.btc_price_delta
              ? FormatTextDelta(prices.btc_price_delta)
              : ''}
          </span>
        </div>
      )}

      <div
        className={twMerge(
          'font-sm py-1 mb-0 align-items-center xxs:ml-2 hidden',
          mode === DexSwitchMode.Cex ? '2xl:block' : 'xl:block'
        )}
      >
        <img
          src={getAssetsUrl() + '/static/img/cryptocurrencies/icons/eth.png'}
          className="xxs:mr-2 align-center h-5"
          alt=""
        />
        ${prices?.eth_price ? formatWithoutDecimals(prices.eth_price) : '--'}{' '}
        <span className="  xxs:ml-1   ">
          {prices?.eth_price_delta
            ? FormatTextDelta(prices.eth_price_delta)
            : ''}
        </span>
      </div>
    </>
  );
}

/*

function ThemeSwitcher() {
  const [theme, setTheme] = useThemeState();

  function switchTheme() {
    if (theme === 'light' || theme === undefined) {
      document.body.setAttribute('theme', 'dark');
      document.body.classList.add('dark');
      setTheme('dark');
    } else {
      document.body.setAttribute('theme', 'light');
      document.body.classList.remove('dark');
      setTheme('light');
    }
  }

  return (
    <Menu.Item>
      {theme === 'light' || theme === undefined ? (
        <button
          className="w-100 xxs:mt-4 lg:mt-0 xxs:px-8 lg:px-4 xxs:py-5 lg:py-2 text-xs text-gray-700 dark:text-white-500 dark:hover:bg-black-800 hover:dark:text-50 border-none hover:bg-black-100 text-left"
          onClick={() => switchTheme()}
        >
          <MoonIcon className="h-5 xxs:mr-2" />
          <span>DARK MODE</span>
        </button>
      ) : (
        <button
          className="w-100 xxs:mt-4 lg:mt-0 xxs:px-8 lg:px-4 xxs:py-5 lg:py-2 text-xs text-gray-700  dark:hover:bg-black-800 dark:text-white-500 hover:dark:text-50 border-none hover:bg-black-100 text-left"
          onClick={() => switchTheme()}
        >
          <SunIcon className="h-5 xxs:mr-2" />
          <span>LIGHT MODE</span>
        </button>
      )}
    </Menu.Item>
  );
}
*/
