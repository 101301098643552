export interface PlanDetail {
  included: boolean;
  perk: string;
}

export interface Plan {
  label: string;
  description: string;
  id: string;
  minFee: number;
  maxFee: number;
  maxPositions: number;
  maxExchanges: number;
  maxSubscriptions: number;
  details: PlanDetail[];
}

export const PLANS: Plan[] = [
  {
    label: 'Pay As You Go',
    description: 'No monthly fix fee, pay based on usage',
    id: 'pay_as_you_go',
    minFee: 0,
    maxFee: 0,
    maxPositions: 20,
    maxExchanges: 1,
    maxSubscriptions: 3,
    details: [
      {
        included: false,
        perk: 'STAR Volume Fee Discount',
      },
      {
        included: false,
        perk: 'STAR Performance Fee Discount',
      },
      {
        included: false,
        perk: 'Vol. Fee capped to $200/mo',
      },
      {
        included: true,
        perk: '3 Active Subscriptions',
      },

      {
        included: true,
        perk: '1 Exchange Key',
      },
      {
        included: true,
        perk: '20 Active Positions',
      },
      {
        included: false,
        perk: 'Analytics',
      },
    ],
  },
  {
    label: 'Trader',
    description: 'Flexible plan for regular traders',
    id: 'trader',
    minFee: 15,
    maxFee: 200,
    maxPositions: 150,
    maxExchanges: 5,
    maxSubscriptions: 15,
    details: [
      {
        included: true,
        perk: 'STAR Volume Fee Discount',
      },
      {
        included: true,
        perk: 'STAR Performance Fee Discount',
      },
      {
        included: true,
        perk: 'Vol. Fee capped to $200/mo',
      },
      {
        included: true,
        perk: '15 Active Subscriptions',
      },

      {
        included: true,
        perk: '5 Exchange Keys',
      },
      {
        included: true,
        perk: '150 Active Positions',
      },
      {
        included: false,
        perk: 'Analytics',
      },
    ],
  },
  {
    label: 'Pro',
    description: 'Advanced plan & analytics for pros',
    id: 'pro',
    minFee: 30,
    maxFee: 200,
    maxPositions: 2500,
    maxExchanges: 100,
    maxSubscriptions: 250,
    details: [
      {
        included: true,
        perk: 'STAR Volume Fee Discount',
      },
      {
        included: true,
        perk: 'STAR Performance Fee Discount',
      },
      {
        included: true,
        perk: 'Vol. Fee capped to $200/mo',
      },
      {
        included: true,
        perk: '250 Active Subscriptions',
      },

      {
        included: true,
        perk: '100 Exchange Keys',
      },
      {
        included: true,
        perk: '2,500 Active Positions',
      },
      {
        included: true,
        perk: 'Analytics',
      },
    ],
  },
  {
    label: 'Institution',
    description: 'Unlimited plan for institutions',
    id: 'institutional',
    minFee: 0,
    maxFee: 0,
    maxPositions: 10000,
    maxExchanges: 10000,
    maxSubscriptions: 10000,
    details: [
      {
        included: true,
        perk: 'Fixed Volume Fee',
      },
      {
        included: true,
        perk: 'Fixed Performance Fee',
      },
      {
        included: true,
        perk: 'Unlimited Active Subscriptions',
      },

      {
        included: true,
        perk: 'Unlimited Exchange Keys',
      },
      {
        included: true,
        perk: 'Unlimited Active Positions',
      },
      {
        included: true,
        perk: 'Analytics',
      },
      {
        included: true,
        perk: 'Dedicated support',
      },
    ],
  },
];
